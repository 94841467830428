<template>
  <div class="text-center">
    <v-dialog v-model="dialog" width="500">
      <template v-slot:activator="{ on, attrs }">
        <input
          type="file"
          name="file"
          id="fileInput"
          class="hidden-input"
          @change="onChange"
          ref="file"
          v-bind="attrs"
          v-on="on"
          accept=".pdf,.jpg,.jpeg,.png"
        />

        <label for="fileInput" class="file-label">
          <u class="btn">
            <v-icon size="20" color="white">mdi-plus</v-icon
            >{{ $t("add attachment") }}</u
          >
        </label>
      </template>

      <v-card v-if="files" class="card">
        <v-card-text>
          <v-container>
            <div class="preview-container mt-4">
              <div class="preview-card">
                <img class="preview-img" :src="generateThumbnail(files)" />
                <p :title="files.name" class="mt-5">
                  {{ files.name }} -
                  {{ Math.round(files.size / 1000) + "kb" }}
                </p>

                <button
                  class="ml-2 times"
                  type="button"
                  @click="remove"
                  title="Remove file"
                >
                  <b>&times;</b>
                </button>
              </div>
            </div>
          </v-container>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            @click="submitFile"
            color="green"
            class="white--text mt-5"
            v-if="files"
            :loading="loading"
          >
            {{ $t("add") }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import axios from "axios";
export default {
  data() {
    return {
      loading: false,
      isDragging: false,
      files: null,
      dialog: false,
    };
  },
  props: {
    Id: Number,
    path: String,
    fetchData: Function,
  },
  methods: {
    ...mapActions({
      fetchLesson: "lessons/fetchLesson",
    }),
    async submitFile() {
      const formData = new FormData();
      formData.append("id", this.$route.params.id);
      formData.append("file", this.files);
      const headers = { "Content-Type": "multipart/form-data" };

      try {
        this.loading = true;
        await axios
          .post(`${this.path}/attachment`, formData, { headers })
          .then((res) => {
        
            res.data.files; // binary representation of the file
            res.status; // HTTP status
          });
        this.$Notifications(
          "تم الاضافة بنجاح",
          { timeout: 2000, rtl: true },
          "success"
        );
        // this.fetchData();
      } catch (err) {
      } finally {
        this.loading = false;
        this.$emit("fetchData");
      }
    },
    onChange() {
      this.files = this.$refs.file.files[0];
   
    },
    generateThumbnail(file) {
      let fileSrc = URL.createObjectURL(file);
      setTimeout(() => {
        URL.revokeObjectURL(fileSrc);
      }, 1000);
      return fileSrc;
    },
    makeName(name) {
      return (
        name.split(".")[0].substring(0, 3) +
        "..." +
        name.split(".")[name.split(".").length - 1]
      );
    },
    remove() {
      this.files = null;
      this.dialog = false;
    },
    dragover(e) {
      e.preventDefault();
      this.isDragging = true;
    },
    dragleave() {
      this.isDragging = false;
    },
    drop(e) {
      e.preventDefault();
      this.$refs.file.files = e.dataTransfer.files;
      this.onChange();
      this.isDragging = false;
    },
  },
};
</script>

<style >
.main {
  /* display: flex; */
  /* flex-grow: 1; */
  /* align-items: center; */
  /* height: 100vh; */
  /* justify-content: center; */
  /* text-align: center; */
}
.dropzone-container {
  padding: 4rem;
  background: #f7fafc;
  border: 1px solid #e2e8f0;
}
.times {
  position: absolute;
  top: 0;
  left: 0;
  font-size: 1.5vw;
}
.card {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.btn {
  text-decoration: none;
  height: 41px;
  color: white;
  min-width: 73px;
  padding: 0 18.2222222222px;
  font-size: 1rem;
  background-color: #4caf50 !important;
  border-color: #4caf50 !important;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  border-radius: 4px;
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-flex: 0;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  font-weight: 400;
  letter-spacing: 0;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  outline: 0;
  position: relative;
  text-decoration: none;
  text-indent: 0;
  text-transform: uppercase;
  -webkit-transition-duration: 0.28s;
  transition-duration: 0.28s;
  -webkit-transition-property: opacity, -webkit-box-shadow, -webkit-transform;
  transition-property: opacity, -webkit-box-shadow, -webkit-transform;
  transition-property: box-shadow, transform, opacity;
  transition-property: box-shadow, transform, opacity, -webkit-box-shadow,
    -webkit-transform;
  -webkit-transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  vertical-align: middle;
  white-space: nowrap;
}
.hidden-input {
  opacity: 0;
  overflow: hidden;
  position: absolute;
  width: 1px;
  height: 1px;
}
.file-label {
  font-size: 20px;
  display: block;
  cursor: pointer;
}
.preview-container {
  display: flex;
  justify-content: center;
  margin-top: 2rem;
  /* background-color: red; */
}
.preview-card {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 80%;
  border: 1px solid #a2a2a2;
  border-radius: 5px;
  padding: 10px;
  margin-left: 5px;
}
.preview-img {
  width: 100px;
  height: 70px;
  border-radius: 5px;
  border: 1px solid #a2a2a2;
  background-color: #a2a2a2;
}
</style>
