<template>
  <v-card elevation="0" class="pt-5">
    <div v-if="loadingStatus" class="table__spinnerWrapper">
      <app-spinner class="mt-5 mr-5"></app-spinner>
    </div>
    <div v-else>
      <v-container>
        <v-row>
          <template v-for="(info, key, index) of homework">
            <v-col md="4" :key="index" v-if="info.value">
              <h3 class="ml-5">
                {{ $t(info.desc) }} :
                <span style="font-weight: 100">{{
                  info.desc == "created at"
                    ? dateFormater(info.value)
                    : info.value
                }}</span>
              </h3>
            </v-col>
          </template>
        </v-row>
        <v-dialog v-model="deleteAttachmentDialog" width="500">
          <v-card>
            <v-card-title class="d-flex justify-center">
              <h2>? {{ $t("are you sure delete ") }}{{ name }}</h2>
            </v-card-title>
            <v-card-actions class="justify-center">
              <v-btn
                color="green"
                class="white--text"
                depressed
                @click="deleteAttachment(attachmentId)"
                :loading="deleteBtnLoading"
                >{{ $t("submit") }}</v-btn
              >
            </v-card-actions>
          </v-card>
        </v-dialog>
        <v-divider class="mt" />
         <v-row align="center" class="mt-2">
          <v-col md="2">
            <h3 class="tests">{{ $t("attachments") }}</h3>
          </v-col>

          <v-col md="2" style="display: flex; justify: start">
            <div>
              <app-attachment-modal
                path="homework"
                @fetchData="fetchData"
              ></app-attachment-modal>
            </div>
          </v-col>
        </v-row>
   
    
        
        <v-row class="mt-5">
          <v-col
            md="2"
            v-for="attachment in homework.attachments"
            :key="attachment.id"
          >
            <div class="cardd">
              <div>
                <v-row>
                  <v-col md="9" >
                    <img class="preview-img" :src="attachment.path" />
                  </v-col>
                  <v-col md="3" >
                    <v-icon
                      color="red"
                      @click="
                        () => {
                       deleteAttachmentDialog = true;
                          attachmentId = attachment.id;
                          name = attachment.name;
                        }
                      "
                      >mdi-delete</v-icon
                    >
                    <div class="mt-5">
                      <FileComponent :Id="attachment.id"></FileComponent>
                    </div>
                  </v-col>
                </v-row>
              </div>
              <div>
                <v-row>
                  <v-col md="9">
                    <div class="test" :title="attachment.name">
                      <span>
                        {{ attachment.name }} -

                        {{ Math.round(attachment.size / 1000) + "kb" }}</span
                      >
                    </div>
                  </v-col>
                </v-row>
              </div>
            </div>
          </v-col>
        </v-row>
      </v-container>
    </div>
  </v-card>
</template>
<script>
import { mapActions, mapGetters } from "vuex";
import axios from "axios";
import attachmentModal from "./attachmentModal.vue";
import FileComponent from "./download.vue";
export default {
  components: {
    appAttachmentModal: attachmentModal,
    FileComponent,
  },
  data() {
    return {
      submitBtnLoading: false,
      deleteAttachmentDialog: false,
      attachmentId: null,
      deleteBtnLoading: false,

      deleteDialog: false,
    };
  },
  computed: {
    ...mapGetters({
      homework: "homeworks/getHomeworkData",
      loadingStatus: "homeworks/getLoadingStatus",
      oneHomework: "homeworks/getOneHomework",
    }),
  },
  methods: {
    ...mapActions({
      fetchHomework: "homeworks/fetchHomework",
    }),
    fetchData(page) {
      this.dialog = false;
      this.fetchHomework(this.$route.params.id);
    },
      generateThumbnail(file) {
      let fileSrc = URL.createObjectURL(file);
      setTimeout(() => {
        URL.revokeObjectURL(fileSrc);
      }, 1000);
      return fileSrc;
    },
    async deleteAttachment(id) {
      try {
          this.deleteBtnLoading = true;
        await axios.delete(`/attachment/${id}`);
        this.deleteAttachmentDialog = false;
        this.$Notifications(
              this.$t('delete success'),
          { timeout: 2000, rtl: true },
          "success"
        );
        this.fetchData();
      } catch (err) {
      } finally {
        this.deleteBtnLoading = false;
      }
    },
    dateFormater(date) {
      var local = new Date(date);
      return local.toLocaleDateString();
    },
  },
  created() {
    if (this.returnAbility("teacher:destroy")) {
      this.displayDeleteIcon = true;
    }

    this.fetchData(1);
  },
};
</script>
<style scoped>
.text {
  border: 1px solid rgb(182, 179, 179);
  width: 100%;
  padding: 0.5vw;
  border-radius: 0.3vw;
  color: gray;
  font-size: 1vw;
  box-shadow: 1px 2px #dad9d9;
}
.titlee {
  font-weight: 700;
  color: rgb(0, 0, 0);
  margin: 0.5vw;
}
.tests {
  font-size: 2vw;
}
.mt {
  margin-top: 2vw;
}

.card {
  width: 25vw;
  min-height: 6vw;

  border-radius: 0.7vw;
  margin-top: 1vw;
}

.desc {
  width: 100%;
  background-color: rgb(218, 130, 15);
}
.attachment-btn {
  background-color: green;
  cursor: pointer;
  opacity: 0.8;
  color: white;
  padding: 0.5vw 0.9vw;
  border-radius: 0.2vw;
  margin-top: 3vw;
}
.title {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}.test {
  /* background-color: palevioletred; */
  text-align: left;
  width: 100%;
  height: 40px;
  font-size: 0.8vw;
  margin-top: 5px;
  overflow: auto;
}
.test::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.test {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}
.cardd {
  /* display: flex;
  justify-content: space-between; */
  border: 1px solid #a2a2a2;
  width: 100%;
  border-radius: 5px;
  /* height: 10vw; */
  /* height: 100px; */
  padding: 5px 10px;
  /* background-color: red; */
  /* overflow: hidden; */
  /* cursor: pointer; */
}
.cardd .preview-img{
  width: 100%;
}
.icon {
  display: flex;
  justify-content: end;
  color: green;
}
.card {
  /* display: flex;
  justify-content: space-between; */
  border: 1px solid #a2a2a2;
  width: 100%;
  height: 10vw;
  padding: 1vw;
  /* background-color: red; */
  /* overflow: hidden; */
  /* cursor: pointer; */
}
.preview-card::-webkit-scrollbar {
  display: none;
}
.times {
  color: red;
  font-size: 1.5vw;
}
.text {
  border: 1px solid rgb(182, 179, 179);
  width: 100%;
  padding: 0.5vw;
  border-radius: 0.3vw;
  color: gray;
  font-size: 1vw;
  box-shadow: 1px 2px #dad9d9;
}
.titlee {
  font-weight: 700;
  color: rgb(0, 0, 0);
  margin: 0.5vw;
}
.tests {
  font-size: 2vw;
}
.mt {
  margin-top: 2vw;
}
.attachment {
  width: 100%;
  height: 7vw;
  border-radius: 1vw;
  background-color: lightgrey;
  text-align: center;
  border: 1px solid grey;
}
.card {
  width: 95%;
  /* min-height: 6vw; */

  border-radius: 0.7vw;
  margin-top: 1vw;
}
</style>
